import {useEffect} from "react";

const Page4 = () => {
    useEffect(() => {
        window.location.href = "https://eternityonboarding.rmissecure.com/_s/client/UserClientLogin.aspx";
    }, []);

    return <div>
    </div>
}

export default Page4;