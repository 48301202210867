import {Button, Row} from "antd";
import React, {useContext, useState} from "react";
import {RouteContext} from "../contexts/routeContext";

const Page0 = () => {
    const context = useContext(RouteContext);
    const [loading, setLoading] = useState(false);

    const handleNext = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            context?.setCurrentPage(1);
        }, 1000)
    }

    return <>
        <div style={{minHeight: 'calc(100vh - 110px)'}}>
            <Row>
                <Row justify={'start'} className="container">
                    <img src={'/logo_client.png'}
                         style={{
                             width: '200px',
                             height: 'auto',
                             display: 'flex',
                             justifySelf: 'center',
                             padding: '10px'
                         }}/>
                </Row>
            </Row>
            <hr style={{marginBottom: '30px'}}/>
            <div className={"container"}>
        <span
            id="ctl00_ContentPlaceHolderMain_lblPageRequirements"><div id="content-message"> <h4>
            Welcome! Thank you for your interest in becoming a certified carrier for Eternity Logistics. We look forward to working with you.
To become an Eternity Logistics Carrier, you must meet the following criteria:
        </h4></div><div
            id="Div3"><h4> To become an Eternity Logistics Carrier, you must meet the following criteria:</h4><ul>
                                            <li>Active operating authority</li>
                                            <li>‘Satisfactory’ or ‘Unrated’ Safety Rating from DOT</li>
                                            <li>
                                                Valid Certificate of Insurance that
                                                <ul className="inner-list">
                                                    <li>has a minimum $100,000 Cargo Coverage </li>
                                                    <li>has minimum $1,000,000 Auto Coverage </li>
                                                </ul>
                                            </li>
                                            <li>Provide Docket# or DOT# for verifying the company information. Intrastate carriers without a DOT# may provide an applicable state registration number to begin registration.</li>
                                            <li>Provide company contact information.</li>
                                            <li>Submit electronic W-9</li>
                                            <li>Complete the Carrier Profile Information.</li>
                                            <li>Read and accept electronic version of Eternity Logistics-Carrier Agreement.</li>
                                        </ul></div></span>
                <Row justify={'center'}>
                    <Button loading={loading} size={'large'} type="primary"
                            style={{display: 'flex', justifySelf: 'center', marginTop: '70px', padding: '30px'}}
                            onClick={() => handleNext()}>
                        GO TO NEXT STEP
                    </Button>
                </Row>
            </div>
        </div>
        <div className={"container"}>
            <div className="container footer-container"><span id="ctl00_UCFooter1_lblFooterText">For help or questions regarding the registration process on this website, contact Giltner Logistics at 208-644-9090.<br/>© 2024 <a
                href="http://www.registrymonitoring.com/">Registry Monitoring Insurance Services, Inc.</a></span>
                <div>
                    <label id="ctl00_UCFooter1_lblPrivacyPolicy" style={{fontWeight: 400}}><a href="#" target="_blank">RMIS
                        Privacy Policy</a></label>
                    <label id="ctl00_UCFooter1_lblDoNotSell" style={{fontWeight: 400}}>&nbsp;|&nbsp;<a href="#"
                                                                                                       target="_blank">Do
                        Not Sell My Personal Information</a></label>
                </div>
                <div style={{
                    backgroundColor: '#2ecc71',
                    borderRadius: '99px',
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    marginRight: '5px',
                }} className="color-rmis-status-dot rmis-status-page none"></div>
                <label className="rmis-status rmis-status-page">RMIS Status - All Systems Operational</label>
                <div>
                    <label id="ctl00_UCFooter1_lblMaintenanceMessage" className="text-danger"></label>
                </div>
                <div id="ctl00_UCFooter1_chatSection" className="chat hidden-sm hidden-md hidden-lg">
                    <div id="ctl00_UCFooter1_transchat">
                        <div id="cimJck" style={{zIndex: 100, position: 'fixed'}}></div>
                        <div id="scmJck" style={{display: 'inline'}} className="AfterHoursChat">
                            <div className="psmtc_mJck"
                                 style={{zIndex: 2147483647, position: 'fixed', bottom: '20px', right: '20px'}}>
                            </div>
                            <div className="psmw_mJck" style={{zIndex: 2147483647, display: 'none'}}></div>
                            <style id="psst_mJck"></style>
                        </div>
                        <div id="sdmJck" style={{display: 'none'}}></div>
                        <noscript>
                            <div style={{display: 'inline'}}><a href="#">Customer Support</a></div>
                        </noscript>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default Page0;

